import { GST_MENU_ALL } from 'src/app/constants/gst';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TAB_LIST_LABEL } from '../../constants/transaction';
import { UsersService } from 'src/app/services/users.service';
import { OmsService } from '../../services/oms.service';
import { PermissionsService } from '../../services/permissions.service';
import { GSTservice } from 'src/app/services/gst.service';
import mixpanel from 'mixpanel-browser';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {

  @ViewChild('subItem[]') subItem: ElementRef[];

  public currentMenu: any = '';
  public userRole: String = '';
  routeSubsription: Subscription;
  slug: any;
  isInternationalCompany = false;
  isChannelPartner = false;

  legalEntityName: string;


  public menuOpened: any = {
    contracts: false,
    contractList: false,
    customers: false,
    reports: false,
    users: false
  };

  public menuItems: any = [{
    name: 'contracts',
    selectables: ['contracts', 'contractList'],
    isOpen: false,
    title: 'Sales Order(SO)',
    iconDefault: 'Contract_Unselected_v2.svg',
    iconSelected: 'Contract_Unselected_v2.svg',
    hasSub: true,
    path: '/contract/view/OPEN',
    params: {},
    appPermission: {
      permissions: ['VIEW_CONTRACTS_MAIN']
    },
    sub: [{
      name: 'contractList',
      selectables: ['contracts', 'contractList'],
      isOpen: false,
      title: 'SO List',
      path: '/contract/view/OPEN',
      params: {},
      appPermission: {
        permissions: ['VIEW_CONTRACTS']
      }
    },
    {
      name: 'customers',
      selectables: ['contracts', 'customers'],
      isOpen: false,
      title: 'Customers',
      path: '/customers/view',
      params: {},
      appPermission: {
        permissions: ['VIEW_CUSTOMERS']
      }
    }
    ]
  },
  {
    name: 'orders',
    selectables: ['orders', 'orderList'],
    isOpen: false,
    title: 'Orders',
    iconDefault: 'Order_Unselected_v2.svg',
    iconSelected: 'Order_Unselected_v2.svg',
    hasSub: true,
    path: '/order/view/pendingpo',
    params: {},
    appPermission: {
      permissions: ['VIEW_ORDERS_MAIN']
    },
    sub: [{
      name: 'orderList',
      selectables: ['orders', 'orderList'],
      isOpen: false,
      title: 'Order List',
      path: '/order/view/pendingpo',
      params: {},
      appPermission: {
        permissions: ['VIEW_ORDERS_MAIN']
      },
      sub: [{
        name: 'orderList',
        selectables: ['orders', 'orderList'],
        isOpen: false,
        title: 'Order List',
        path: '/order/view/pendingpo',
        params: {},
        appPermission: {
          permissions: ['VIEW_ORDERS']
        }
      },
      {
        name: 'billsList',
        selectables: ['orders', 'billsList'],
        isOpen: false,
        title: 'Bills',
        path: '/bills/invoices/view/verification-pending',
        params: {},
        appPermission: {
          permissions: ['VIEW_BILLS']
        }
      },
      {
        name: 'creditNote',
        selectables: ['orders', 'creditNote'],
        isOpen: false,
        title: 'Credit Note',
        path: '/order/creditNote/view/INPROCESS',
        appPermission: {
          permissions: ['VIEW_CREDIT_NOTES']
        }
      },
      {
        name: 'debitNote',
        selectables: ['orders', 'debitNote'],
        isOpen: false,
        title: 'Debit Note',
        path: '/order/debitNote/view/OPEN',
        appPermission: {
          permissions: ['VIEW_DEBIT_NOTES']
        }
      },
      {
        name: 'paymentRequest',
        selectables: ['orders', 'paymentRequest'],
        isOpen: false,
        title: 'Payment Request',
        path: '/payment/verification-pending',
        params: {},
        appPermission: {
          permissions: ['VIEW_PAYMENT_REQUESTS']
        }
      },
      {
        name: 'loan',
        selectables: ['loan'],
        isOpen: false,
        title: 'Financing',
        path: '/loan/view/processing',
        params: {},
        appPermission: {
          permissions: ['VIEW_FINANCING']
        }
      },
      {
        name: 'suppliers',
        selectables: ['orders', 'suppliers'],
        isOpen: false,
        title: 'Suppliers',
        path: '/suppliers/view/notVerified',
        params: {},
        appPermission: {
          permissions: ['VIEW_SUPPLIERS_MAIN']
        }
      }
      ]
    },
    {
      name: 'billsList',
      selectables: ['orders', 'billsList'],
      isOpen: false,
      title: 'Bills',
      path: '/bills/invoices/view/approval-pending',
      params: {},
      appPermission: {
        permissions: ['VIEW_BILLS']
      }
    },
    {
      name: 'creditNote',
      selectables: ['orders', 'creditNote'],
      isOpen: false,
      title: 'Credit Note',
      path: '/order/creditNote/view/INPROCESS',
      appPermission: {
        permissions: ['VIEW_CREDIT_NOTES']
      }
    },
    {
      name: 'debitNote',
      selectables: ['orders', 'debitNote'],
      isOpen: false,
      title: 'Debit Note',
      path: '/order/debitNote/view/OPEN',
      appPermission: {
        permissions: ['VIEW_DEBIT_NOTES']
      }
    },
    {
      name: 'paymentRequest',
      selectables: ['orders', 'paymentRequest'],
      isOpen: false,
      title: 'Payment Request',
      path: '/payment/verification-pending',
      params: {},
      appPermission: {
        permissions: ['VIEW_PAYMENT_REQUESTS']
      }
    },
    {
      name: 'loan',
      selectables: ['loan'],
      isOpen: false,
      title: 'Financing',
      path: '/loan/view/processing',
      params: {},
      appPermission: {
        permissions: ['VIEW_FINANCE_MAIN']
      },
      sub: [{
        name: 'taxInvoice',
        selectables: ['finance', 'taxInvoice'],
        isOpen: false,
        title: 'Invoice',
        path: '/finance/taxInvoice/view/CREATED',
        appPermission: {
          permissions: ['VIEW_CUSTOMER_TAX_INVOICES']
        }
      },
      {
        name: 'proformaInvoice',
        selectables: ['finance', 'proformaInvoice'],
        isOpen: false,
        title: 'Proforma Invoice',
        path: '/finance/proformaInvoice/view/CREATED',
        appPermission: {
          permissions: ['VIEW_CUSTOMER_PROFORMA_INVOICES']
        }
      },
      {
        name: 'creditNote',
        selectables: ['finance', 'creditNote'],
        isOpen: false,
        title: 'Credit Note',
        path: '/finance/creditNote/view/created',
        appPermission: {
          permissions: ['VIEW_CREDIT_NOTES']
        }
      },
      {
        name: 'debitNote',
        selectables: ['finance', 'debitNote'],
        isOpen: false,
        title: 'Debit Note',
        path: '/finance/debitNote/view/created',
        appPermission: {
          permissions: ['VIEW_DEBIT_NOTES']
        }
      },
      {
        name: 'eWayBill',
        selectables: ['finance', 'eWayBill'],
        isOpen: false,
        title: 'E-Way Bill',
        path: '/finance/eWayBill/view/GENERATED',
        // appPermission: {
        //   permissions: ['VIEW_E_WAY_BILL']
        // }
      },
      {
        name: 'transaction',
        selectables: ['finance', 'transaction'],
        isOpen: false,
        title: 'Transaction',
        path: `/finance/transaction/view/${TAB_LIST_LABEL.APPROVED.label}/BANK`,
      },
      {
        name: 'financierRepayment',
        selectables: ['finance', 'financierRepayment'],
        isOpen: false,
        title: 'Financier Repayment',
        path: '/finance/financierRepayment/view/dues',
      },
      {
        name: 'letterofCredit',
        selectables: ['finance', 'letterofCredit'],
        isOpen: false,
        title: 'Zetwerk LC',
        path: '/finance/letterofCredit/view',
        // appPermission: {
        //   permissions: [] // Needs to change
        // }
      },
      {
        name: 'customer-letter-of-credit',
        selectables: ['finance', 'customer-letter-of-credit'],
        isOpen: false,
        title: 'Customer LC',
        path: '/finance/customer-letter-of-credit/view/CREATED',
        // appPermission: {
        //   permissions: [] // Needs to change
        // }
      },
      {
        name: 'delivery-challan',
        selectables: ['finance', 'delivery-challan'],
        isOpen: false,
        title: 'Delivery Challan',
        path: '/finance/delivery-challan/view/CREATED',
      },
      ]
    },
    {
      name: 'suppliers',
      selectables: ['orders', 'suppliers'],
      isOpen: false,
      title: 'Suppliers',
      path: '/suppliers/view/notVerified',
      params: {},
      appPermission: {
        permissions: ['VIEW_SUPPLIERS_MAIN']
      }
    },
    ]
  },
  {
    name: 'workflow',
    selectables: ['workflow'],
    isOpen: false,
    title: 'Z-Workflowz',
    iconDefault: 'Z-Workflowz-inactive.svg',
    iconSelected: 'Z-Workflowz.svg',
    hasSub: false,
    path: '/workflow',
    params: {}
  },
  {
    name: 'product-master',
    selectables: ['product-master', 'product-master-sales'],
    isOpen: false,
    hasSub: true,
    title: 'Product Master',
    iconDefault: 'item-master-icon.svg',
    iconSelected: 'item-master-icon.svg',
    path: '/product-master',
    params: {},
    sub: [
      {
        name: 'product-master-sales',
        selectables: ['product-master', 'product-master-sales'],
        isOpen: false,
        title: 'Sales',
        path: '/product-master/sales',
        params: {}
      },
      {
        name: 'product-master-procurement',
        selectables: ['product-master', 'product-master-procurement'],
        isOpen: false,
        title: 'Procurement',
        path: '/product-master/procurement',
        params: {}
      },
    ]
  },
  {
    name: 'boq',
    selectables: ['boq'],
    isOpen: false,
    title: 'BOQ',
    iconDefault: 'BOQ_Unselected_v2.svg',
    iconSelected: 'BOQ_Selected_v2.svg',
    hasSub: false,
    path: '/boq/view',
    params: {},
    appPermission: {
      permissions: ['VIEW_BOQ_MAIN']
    }
  },
  // {
  //   name: 'loan',
  //   selectables: ['loan'],
  //   isOpen: false,
  //   title: 'Financing',
  //   iconDefault: 'finance.svg',
  //   iconSelected: 'finance_selected.svg',
  //   hasSub: false,
  //   path: '/loan/view/processing',
  //   params: {}
  // },
  {
    name: 'reports',
    selectables: ['reports'],
    isOpen: false,
    title: 'Reports',
    iconDefault: 'Report_Unselected_v2.svg',
    iconSelected: 'Report_Selected_v2.svg',
    hasSub: false,
    path: '/metrics',
    params: {},
    appPermission: {
      permissions: ['VIEW_REPORTS_MAIN']
    }
  },
  {
    name: 'users',
    selectables: ['users'],
    isOpen: false,
    title: 'Users',
    iconDefault: 'Users_Unselected_v2.svg',
    iconSelected: 'Users_Selected_v2.svg',
    hasSub: false,
    path: '/users/view',
    params: {},
    appPermission: {
      permissions: ['VIEW_USERS_MAIN']
    }
  },
  {
    name: 'vfs',
    selectables: ['vfs'],
    isOpen: false,
    title: 'VFS',
    iconDefault: 'VFS_menu_unselected.svg',
    iconSelected: 'VFS_menu_selected.svg',
    hasSub: false,
    path: '/vfs/list',
    params: {},
    appPermission: {
      permissions: ['VIEW_VFS']
    }
  },
  {
    name: 'finance',
    selectables: ['finance', 'ra-bill'],
    isOpen: false,
    title: 'Finance',
    iconDefault: 'Finance_Unselected_v2.svg',
    iconSelected: 'Finance_Unselected_v2.svg',
    hasSub: true,
    path: '/finance/ra-bill/view/SUBMITTED',
    appPermission: {
      permissions: ['VIEW_FINANCE_MAIN']
    },
    sub: [
      {
        name: 'ra-bill',
        selectables: ['finance', 'ra-bill'],
        isOpen: false,
        title: 'RA Bill',
        path: '/finance/ra-bill/view/SUBMITTED'
      },
      {
        name: 'invoice-request',
        selectables: ['finance', 'invoice-request'],
        isOpen: false,
        title: 'Invoice Request',
        path: '/finance/invoice-request/view/OPEN'
      },
      {
        name: 'taxInvoice',
        selectables: ['finance', 'taxInvoice'],
        isOpen: false,
        title: 'Invoice',
        path: '/finance/taxInvoice/view/CREATED',
        appPermission: {
          permissions: ['VIEW_CUSTOMER_TAX_INVOICES']
        }
      },
      {
        name: 'proformaInvoice',
        selectables: ['finance', 'proformaInvoice'],
        isOpen: false,
        title: 'Proforma Invoice',
        path: '/finance/proformaInvoice/view/CREATED',
        appPermission: {
          permissions: ['VIEW_CUSTOMER_PROFORMA_INVOICES']
        }
      },
      {
        name: 'creditNote',
        selectables: ['finance', 'creditNote'],
        isOpen: false,
        title: 'Credit Note',
        path: '/finance/creditNote/view/created',
        appPermission: {
          permissions: ['VIEW_CREDIT_NOTES']
        }
      },
      {
        name: 'debitNote',
        selectables: ['finance', 'debitNote'],
        isOpen: false,
        title: 'Debit Note',
        path: '/finance/debitNote/view/created',
        appPermission: {
          permissions: ['VIEW_DEBIT_NOTES']
        }
      },
      {
        name: 'invoice-discounting',
        selectables: ['finance', 'invoice-discounting'],
        isOpen: false,
        title: 'Invoice Discounting',
        path: '/finance/invoice-discounting/view/SUBMITTED',
        appPermission: {
          permissions: ['VIEW_INVOICE_DISCOUNTING']
        }
      },
      {
        name: 'factory-payment',
        selectables: ['finance', 'factory-payment'],
        isOpen: false,
        title: 'Factory Payments',
        path: '/finance/factory-payment/view/SCHEDULE_CREATED'
      },
      {
        name: 'eWayBill',
        selectables: ['finance', 'eWayBill'],
        isOpen: false,
        title: 'E-Way Bill',
        path: '/finance/eWayBill/view/GENERATED',
        // appPermission: {
        //   permissions: ['VIEW_E_WAY_BILL']
        // }
      },
      {
        name: 'transaction',
        selectables: ['finance', 'transaction'],
        isOpen: false,
        title: 'Supplier Transaction',
        path: `/finance/transaction/view/${TAB_LIST_LABEL.APPROVED.label}/BANK`,
      },
      {
        name: 'customer-transaction',
        selectables: ['finance', 'customer-transaction'],
        isOpen: false,
        title: 'Customer Transaction',
        path: `/finance/customer-transaction/view/${TAB_LIST_LABEL.APPROVED.label}`,
      },
      {
        name: 'financierRepayment',
        selectables: ['finance', 'financierRepayment'],
        isOpen: false,
        title: 'Financier Repayment',
        path: '/finance/financierRepayment/view/dues',
      },
      {
        name: 'letterofCredit',
        selectables: ['finance', 'letterofCredit'],
        isOpen: false,
        title: 'Zetwerk LC',
        path: '/finance/letterofCredit/view',
        // appPermission: {
        //   permissions: [] // Needs to change
        // }
      },
      {
        name: 'bank-master',
        selectables: ['finance', 'bank-master'],
        isOpen: false,
        title: 'Zetwerk Banks',
        path: '/finance/bank-master',
        appPermission: {
          permissions: ['VIEW_ZETWERK_BANKS']
        }
      },
      {
        name: 'investor-master',
        selectables: ['finance', 'investor-master'],
        isOpen: false,
        title: 'Investors',
        path: '/finance/investor-master',
        appPermission: {
          permissions: ['VIEW_INVESTOR']
        }
      },
      {
        name: 'customer-letter-of-credit',
        selectables: ['finance', 'customer-letter-of-credit'],
        isOpen: false,
        title: 'Customer LC',
        path: '/finance/customer-letter-of-credit/view/CREATED',
        // appPermission: {
        //   permissions: [] // Needs to change
        // }
      },
      {
        name: 'delivery-challan',
        selectables: ['finance', 'delivery-challan'],
        isOpen: false,
        title: 'Delivery Challan',
        path: '/finance/delivery-challan/view/CREATED',
      },
      {
        name: 'paymentTerms',
        selectables: ['finance', 'paymentTerms'],
        isOpen: false,
        title: 'Payment Terms',
        path: '/finance/paymentTerms/list',
      },
      {
        name: 'transactionReport',
        selectables: ['finance', 'transactionReport'],
        isOpen: false,
        title: 'Transaction Report',
        path: '/finance/transactionReport/list',
        appPermission: {
          permissions: ['VIEW_TRANSACTION_REPORTS']
        }
      },
      {
        name: 'bank-statement',
        selectables: ['finance', 'bank-statement'],
        isOpen: false,
        title: 'Bank Statements',
        path: '/finance/bank-statement/list',
      },
      {
        name: 'credit-transaction',
        selectables: ['finance', 'credit-transaction'],
        isOpen: false,
        title: 'Credit Transaction',
        path: '/finance/bank-transaction/CREDIT/list/ALLOCATION_PENDING',
      },
      {
        name: 'debit-transaction',
        selectables: ['finance', 'debit-transaction'],
        isOpen: false,
        title: 'Debit Transaction',
        path: '/finance/bank-transaction/DEBIT/list/ALLOCATION_PENDING',
      },
      {
        name: 'oms-receipts',
        selectables: ['finance', 'oms-receipts'],
        isOpen: false,
        title: 'OMS Receipts',
        path: '/finance/oms-receipts/list/OMS_ALLOCATED',
      },

    ]
  },
  {
    name: 'approvals',
    selectables: ['approvals', 'finance-approval'],
    isOpen: false,
    title: 'Approvals',
    iconDefault: 'VFS_menu_unselected.svg',
    iconSelected: 'VFS_menu_selected.svg',
    hasSub: true,
    path: '/approvals/finance-approval/PENDING',
    params: {},
    appPermission: {
      permissions: ['VIEW_APPROVALS']
    },
    sub: [
      {
        name: 'finance-approval',
        selectables: ['approvals', 'finance-approval'],
        isOpen: false,
        title: 'Invoice',
        path: '/approvals/finance-approval/PENDING',
        params: {},
        appPermission: {
          permissions: ['VIEW_INVOICE_APPROVALS']
        }
      },
      {
        name: 'grn-approval',
        selectables: ['approvals', 'grn-approval'],
        isOpen: false,
        title: 'Verify GRN',
        path: '/approvals/grn-approval/PENDING',
        params: {},
        appPermission: {
          permissions: ['VIEW_GRN_APPROVALS']
        }
      }
    ]
  },
  {
    name: 'gst',
    selectables: ['gst'],
    isOpen: false,
    title: 'GST Setup',
    iconDefault: 'GST_Setup_Unselected_v2.svg',
    iconSelected: 'GST_Setup_Selected_v2.svg',
    hasSub: false,
    path: '/gst',
    params: {}
  },
  {
    name: 'payment-ticket',
    selectables: ['payment-ticket'],
    isOpen: false,
    title: 'Payment Ticket',
    iconDefault: 'Payment_Ticket_Unselected.svg',
    iconSelected: 'Payment_Ticket_Selected.svg',
    hasSub: false,
    path: '/payment-ticket/list/active',
    params: {},
    appPermission: {
      permissions: ['VIEW_PAYMENT_TICKET']
    }
  },
  {
    name: 'collections',
    selectables: ['collections', 'taxInvoice'],
    isOpen: false,
    title: 'Collections',
    iconDefault: 'Collections_Unselected.svg',
    iconSelected: 'Collections_Selected.svg',
    hasSub: true,
    path: '/collections/taxInvoice/invoice',
    params: {},
    appPermission: {
      permissions: ['VIEW_COLLECTIONS']
    },
    sub: [
      {
        name: 'taxInvoice',
        selectables: ['collections', 'taxInvoice'],
        isOpen: false,
        title: 'Invoice',
        path: '/collections/taxInvoice/invoice',
        appPermission: {
          permissions: ['VIEW_COLLECTION_INVOICES']
        }
      },
    ]
  },
  {
    name: 'book-closure',
    selectables: ['book-closure'],
    isOpen: true,
    title: 'Book Closure',
    iconDefault: 'Collections_Unselected.svg',
    iconSelected: 'Collections_Selected.svg',
    hasSub: false,
    path: '/book-closure/OPEN',
    params: {},
    appPermission: {
      permissions: ['FINANCE_BOOK_CLOSURE']
    }
  },
  {
    name: 'settings',
    selectables: ['settings'],
    isOpen: false,
    title: 'Settings',
    iconDefault: 'Settings_Unselected_v2.svg',
    iconSelected: 'Settings_Selected_v2.svg',
    hasSub: true,
    path: '/settings',
    appPermission: {
      permissions: ['MANAGE_OMS_SETTINGS']
    },
    params: {},
    sub: [
      //   {
      //   name: 'backdate',
      //   selectables: ['settings', 'backdate'],
      //   isOpen: false,
      //   title: 'Backdating',
      //   path: '/settings/backdate'
      // },
      {
        name: 'business-unit',
        selectables: ['settings', 'business-unit'],
        isOpen: false,
        title: 'Business Units',
        path: '/settings/business-unit'
      },
      {
        name: 'project-type',
        selectables: ['settings', 'project-type'],
        isOpen: false,
        title: 'Project Types',
        path: '/settings/project-type'
      },
      {
        name: 'gst-defaulter',
        selectables: ['settings', 'gst-defaulter'],
        isOpen: false,
        title: 'GST Defaulter',
        path: '/settings/gst-defaulter/view',
      },
      // {
      //   name: 'book-closure',
      //   selectables: ['settings', 'book-closure'],
      //   isOpen: false,
      //   title: 'Book Closure',
      //   path: '/settings/book-closure/OPEN'
      // },
    ]
  },
    // {
    //   name: 'inventory',
    //   selectables: ['inventory'],
    //   isOpen: false,
    //   title: 'Inventory',
    //   iconDefault: 'Inventory_unselected.svg',
    //   iconSelected: 'Inventory_selected.svg',
    //   hasSub: false,
    //   path: '/inventory',
    //   params: {}
    // },
    //   // ,
    // {
    //   name: 'currencyExcange',
    //   selectables: ['currencyExcange'],
    //   isOpen: false,
    //   title: 'Currency Exchange',
    //   iconDefault: 'exchange.svg',
    //   iconSelected: 'exchange_selected.svg',
    //   hasSub: false,
    //   path: '/rate/view',
    //   params: {}
    // },
    // {
    //   name: 'pivot',
    //   selectables: ['pivot'],
    //   isOpen: false,
    //   title: 'Pivot',
    //   iconDefault: 'pivot.svg',
    //   iconSelected: 'pivot_selected.svg',
    //   hasSub: false,
    //   path: '/pivot',
    //   params: {}
    // }
  ];
  companyNameToDisplay = 'Zetwerk';

  constructor(
    private router: Router,
    private _omsService: OmsService,
    private _ps: PermissionsService,
    private _gstService: GSTservice,
    private activatedRoute: ActivatedRoute,
    private userService: UsersService
  ) {
    this.companyNameToDisplay = this._omsService.getCompanyNameToDisplay();
    this.slug = this.activatedRoute.snapshot.params['slug'];
    this.currentMenu = this.router.url.split('/')[3];
    this.selectMenu();
    this.routeSubsription = router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.currentMenu = val.url.split('/')[3];
        if (val.url.split('/')[3] == 'order' && val.url.split('/')[4] == 'creditNote') {
          this.currentMenu = 'creditnote';
        } else if (val.url.split('/')[3] == 'order' && val.url.split('/')[4] == 'debitNote') {
          this.currentMenu = 'debitnote';
        } else if (val.url.split('/')[3] == 'order' && val.url.split('/')[4] == 'gst-defaulter') {
          this.currentMenu = 'gst-defaulter';
        } else if (val.url.split('/')[3] == 'approvals' && val.url.split('/')[4] == 'grn-approval') {
          this.currentMenu = 'grn-approval';
        } else if (val.urlAfterRedirects.split('/')[3] == 'collections') {
          this.currentMenu = 'collections';
        } else if (val.url.split('/')[3] == 'item-master' && val.url.split('/')[4] == 'procurement') {
          this.currentMenu = 'item-master-procurement';
        } else if (val.url.split('/')[4] == 'bank-transaction' && val.url.split('/')[5] && val.url.split('/')[5] == 'DEBIT') {
          this.currentMenu = 'debit-transaction';
        } else if (val.url.split('/')[4] == 'bank-transaction' && val.url.split('/')[5] && val.url.split('/')[5] == 'CREDIT') {
          this.currentMenu = 'credit-transaction';
        } else if (val.url.split('/')[3] == 'customer-groups') {
          this.currentMenu = 'customers';
        } else if (val.url.split('/')[3] == 'supplier-groups') {
          this.currentMenu = 'suppliers';
        } else if (val.url.split('/')[3] == 'product-master' && val.url.split('/')[4] == 'procurement') {
          this.currentMenu = 'product-master-procurement';
        }
        this.selectMenu();
      }
    });
  }

  ngOnInit() {
    this.isInternationalCompany = this._omsService.getIsInternationalCompany();
    this.isChannelPartner = this._omsService.getIsChannelPartner();
    this.slug = this.activatedRoute.snapshot.params['slug'];
    this.userRole = this._omsService.getUserRole();
    const type = localStorage.getItem('gst') || GST_MENU_ALL.value;
    this._gstService.gstSelection(type);
    // this.currentMenu = this.router.url.split('/')[1];
    // this.selectMenu();

    this.setLegalEntityName();
  }

  ngDoCheck() {
    this.slug = this.activatedRoute.snapshot.params['slug'];
    this.setLegalEntityName();
    this.isInternationalCompany = this._omsService.getIsInternationalCompany();
  }

  selectMenu() {
    switch (this.currentMenu) {
      case 'contract':
        this.markMenu(['contracts', 'contractList'], false);
        break;
      case 'order':
        this.markMenu(['orders', 'orderList'], false);
        break;
      case 'creditnote':
        this.markMenu(['orders', 'creditNote'], false);
        break;
      case 'debitnote':
        this.markMenu(['orders', 'debitNote'], false);
        break;
      case 'eWayBill':
        this.markMenu(['orders', 'eWayBill'], false);
        break;
      case 'loan':
        this.markMenu(['orders', 'loan'], false);
        break;
      case 'gst-defaulter':
        this.markMenu(['settings', 'gst-defaulter'], false);
        break;
      case 'suppliers':
        this.markMenu(['orders', 'suppliers'], false);
        break;
      case 'payment':
        this.markMenu(['orders', 'paymentRequest'], false);
        break;
      case 'customers':
        this.markMenu(['contracts', 'customers'], false);
        break;
      case 'users':
        this.markMenu(['users'], false);
        break;
      case 'product-master':
        this.markMenu(['product-master', 'product-master-sales'], false);
        break;
      case 'product-master-procurement':
        this.markMenu(['product-master', 'product-master-procurement'], false);
        break;
      case 'vfs':
        this.markMenu(['vfs'], false);
        break;
      case 'finance':
        this.markMenu(['finance', 'taxInvoice'], true);
        break;
      case 'pivot':
        this.markMenu(['pivot'], false);
        break;
      case 'rate':
        this.markMenu(['currencyExcange'], false);
        break;
      case 'metrics':
        this.markMenu(['reports'], false);
        break;
      case 'bills':
        this.markMenu(['orders', 'billsList'], false);
        break;
      case 'gst':
        this.markMenu(['gst'], false);
        break;
      case 'settings':
        this.markMenu(['settings', 'backdate'], true);
        break;
      case 'boq':
        this.markMenu(['boq'], false);
        break;
      case 'workflow':
        this.markMenu(['workflow'], false);
        break;
      case 'payment-ticket':
        this.markMenu(['payment-ticket'], false)
        break;
      case 'collections':
        this.markMenu(['collections', 'taxInvoice'], true);
        break;
      case 'grn-approval':
        this.markMenu(['approvals', 'grn-approval'], false);
        break;
      case 'approvals':
        this.markMenu(['approvals', 'finance-approval'], false);
        break;
      case 'inventory':
        this.markMenu(['inventory'], false);
        break;
      case 'book-closure':
        this.markMenu(['book-closure'], true);
      case 'credit-transaction':
        this.markMenu(['finance', 'credit-transaction'], false);
        break;
      case 'debit-transaction':
        this.markMenu(['finance', 'debit-transaction'], false);
        break;
      default:
        this.markMenu(['contracts', 'contractList'], false);
        break;
    }
  }

  hasPermission(item) {
    if (item.appPermission) {
      return this._ps.userHasPermission(item.appPermission);
    }
    return true;
  }

  hideForCompany(item) {
    let result = false;
    const menuToHide = ['eWayBill', 'invoice-discounting', 'loan', 'financierRepayment', 'gst'];
    if (this.isInternationalCompany && menuToHide.includes(item.name)) {
      result = true;
    }
    return result;
  }

  markMenu(selectables, considerSecondary) {
    // is it main menu? does it have sub menu? is it already open > then close
    if (selectables.length === 1) {
      const itemIndex = this.menuItems.findIndex(x => x.name === selectables[0]);
      if (this.menuItems[itemIndex].hasSub && this.menuItems[itemIndex].isOpen) {
        this.menuItems[itemIndex].isOpen = false;
        return false;
      }
    }

    let m = 0;
    let s = 0;
    for (const menu of this.menuItems) {
      this.menuItems[m].isOpen = false;
      const menuIndex = selectables.indexOf(menu.name);

      if (menuIndex !== -1) {
        // mark open
        this.menuItems[m].isOpen = true;
      }
      // has sub items?
      if (menu.hasSub && !considerSecondary) {
        s = 0;
        for (const sub of menu.sub) {
          this.menuItems[m].sub[s].isOpen = false;
          const subMenuIndex = selectables.indexOf(sub.name);
          if (subMenuIndex !== -1) {
            // mark open
            this.menuItems[m].sub[s].isOpen = true;
          }
          s++;
        }
      } else if (menu.hasSub && considerSecondary) {
        const urlParam = this.router.url.split('/')[4];

        s = 0;
        for (const sub of menu.sub) {
          this.menuItems[m].sub[s].isOpen = false;
          // const subMenuIndex = selectables.indexOf(sub.name);
          if (urlParam === sub.name || urlParam.split('?')[0] === sub.name) {
            this.menuItems[m].sub[s].isOpen = true;
          }
          s++;
        }
      }
      m++;
    }

  }

  setMenu(selectables) {
    // is it main menu? does it have sub menu? is it already open > then close
    if (selectables.length === 1) {
      const itemIndex = this.menuItems.findIndex(x => x.name === selectables[0]);
      if (this.menuItems[itemIndex] && this.menuItems[itemIndex].hasSub && this.menuItems[itemIndex].isOpen) {
        this.menuItems[itemIndex].isOpen = false;
        return false;
      }
    }

    let m = 0;
    let s = 0;
    for (const menu of this.menuItems) {
      this.menuItems[m].isOpen = false;
      const menuIndex = selectables.indexOf(menu.name);

      if (menuIndex !== -1) {
        // mark open
        this.menuItems[m].isOpen = true;
      }
      // has sub items?
      if (menu.hasSub) {
        s = 0;
        for (const sub of menu.sub) {
          this.menuItems[m].sub[s].isOpen = false;

          // Updating title for LC based on selected legalEntity
          if (sub.name === 'letterofCredit') {
            sub.title = `${this.legalEntityName} LC`;
          }

          const subMenuIndex = selectables.indexOf(sub.name);
          if (subMenuIndex !== -1) {
            // mark open
            this.menuItems[m].sub[s].isOpen = true;
            // return false;
          }
          s++;
        }
      }
      m++;
    }
  }

  ngOnDestroy() {
    this.routeSubsription.unsubscribe();
  }

  isUserAdmin(userRole) {
    if (!userRole) {
      return false;
    }

    return userRole.includes('ADMIN') || userRole.includes('IT_ADMIN');
  }

  logoutUser() {
    this._omsService.logOutWithCookie().subscribe(result => {
      if (localStorage) {
        localStorage.clear();
      }
      mixpanel.reset() // Clears out mixpanel data
      this.router.navigate(['login']);
      // need to fix company selection, as of now it's temporary solution.
      window.location.reload();
    });
  }

  sideMenuColor() {
    return this.isChannelPartner ? 'channel-partner' : this.slug;
  }

  openMenuColor(open?) {
    if (open) {
      return `sub-open-bg-${this.isChannelPartner ? 'channel-partner' : this.slug}`;
    }
    return `li-${this.isChannelPartner ? 'channel-partner' : this.slug}-hover`;
  }

  getTextColor(menu?) {
    if (menu && !menu.hasSub && menu.isOpen) {
      return 'selected';
    }
    return `a-${this.isChannelPartner ? 'channel-partner' : this.slug}`;
  }

  setLegalEntityName() {
    const { companyDetails } = this._omsService.getSelectedCompanyIdAndSlug();

    this.legalEntityName = companyDetails.name;
  }
}
